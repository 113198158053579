<template>
	<br-generic-list-base :derived-component="_self">
	</br-generic-list-base>
</template>

<script>
	
	import { B_REST_Vuetify_GenericList } from "@/bREST/core/implementations/vue";
	
	export default {
		name: "postalMailInvoiceBatchList",
		mixins: B_REST_Vuetify_GenericList.createMixin({
			formComponent: () => import("./PostalMailInvoiceBatchForm.vue"),
			modelName: "PostalMailInvoiceBatch",
			fromLoader: {
				apiBaseUrl: "/postalMailInvoiceBatches/",
			},
			cols: {
				createdDT:   {fieldNamePaths:"createdDT",   style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT}},
				description: {fieldNamePaths:"description", style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT}},
				isNew:       {
					fieldNamePaths:"isNew",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
					toCellContent(col, model, defaultContent) { return model.select("isNew").val ? "✔" : ""; },
				},
			},
			globalActions: {},
			row: {
				checkbox: {isEnabled:true},
				actions: {
					...B_REST_Vuetify_GenericList.RowAction.defineCommonAction_edit({icon:"mdi-eye"}),
				},
			},
			filters: {
				isNew: {fieldNamePath:"isNew"},
			},
		}),
	};
	
</script>
