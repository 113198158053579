<template>
	<br-generic-list-base :derived-component="_self">
		<template #data-table-area--before>
			<v-btn @click="gpsTracker_startCron()" color="primary":disabled="isGmapUpdateActivated">{{ t('buttonStartGPS') }}</v-btn>
			<div ref="gMap" class="mb-5" style="height: 700px;"></div>
			<div v-if="googleMapsLinks.length>0" class="mb-8">
				<h2 class="mb-2">{{ t("routes") }}</h2>
				<v-row>
					<v-col cols="12" sm="6" md="4" v-for="(loop_googleMapsLink, index) in googleMapsLinks" :key="index">
						<a :href="loop_googleMapsLink.link" target="_blank">{{ loop_googleMapsLink.label }}</a>
					</v-col>
				</v-row>
			</div>
		</template>
		<template #item.calc_latitude="{ rowInfo, colInfo, modelField, finalContent }">
			<v-icon v-if="rowInfo.model.select('calc_longitude').val != null && rowInfo.model.select('calc_latitude').val != null">mdi-map-check</v-icon>
		</template>
		<template #item.planned_order="{ rowInfo, colInfo, modelField, finalContent }">
			<v-edit-dialog large @save="set_planned_order(rowInfo.model)">
				<v-chip link outlined> <span>{{ finalContent ?? "-" }}</span> </v-chip>
				<template #input>
					<div class="mt-4 text-h6">{{ t('cols.planned_order.modify') }}</div>
					<v-text-field v-model="modelField.val" :placeholder="t('cols.planned_order.label')" type="number"></v-text-field>
				</template>
			</v-edit-dialog>
		</template>
	</br-generic-list-base>
</template>

<script>
	
	import { B_REST_Vuetify_GenericList } from "@/bREST/core/implementations/vue";
	import B_REST_Descriptor from "@/bREST/core/classes/descriptors/B_REST_Descriptor.js";
	import { Loader } from "@googlemaps/js-api-loader";
	
	export default {
		name: "interventionList",
		mixins: B_REST_Vuetify_GenericList.createMixin({
			formComponent: () => import("./InterventionForm.vue"),
			modelName: "Intervention",
			fromLoader: {
				apiBaseUrl: "/interventions/",
			},
			cols: {
				pk: {
					fieldNamePaths:"priority",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
					toCellContent(col,model,defaultContent) { return model.pk; },
				},
				calc_address: {
					fieldNamePaths:"calc_address",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				status: {fieldNamePaths:"status", style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT}},
				reasons: {
					fieldNamePaths:"reasons",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
					toCellContent(col,model,defaultContent)
					{
						const reasonTags = model.select("reasons").val;
						if (!reasonTags || reasonTags.length===0) { return "-"; }
						
						const sharedListItems = this.$bREST.sharedLists_getItems("interventionReasonList");
						const reasonVals      = reasonTags.map(loop_tag => sharedListItems.find(loop_sharedListItem=>loop_sharedListItem.key===loop_tag)?.label ?? "???");
						
						return reasonVals.join(", ");
					},
				},
				assignedTo: {
					fieldNamePaths: "assigned_to_fk|assignedTo.<toLabel>",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
					toCellContent(col, model) {
						return model.select("assignedTo")?.toLabel()??null;
					}
				},
				calc_latitude: {
					fieldNamePaths: "calc_latitude|calc_longitude",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				planned_order: {fieldNamePaths:"planned_order", style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT}},
			},
			globalActions: {
				...B_REST_Vuetify_GenericList.GlobalAction.defineCommonAction_add({}),
			},
			row: {
				checkbox: {isEnabled:true},
				style: {
					class(component, model) {
						return "intervention-priority--" + model.select('priority').val
					}
				},
				actions: {
					...B_REST_Vuetify_GenericList.RowAction.defineCommonAction_delete({
						isEnabled(action,model,isCtrlClickOrMiddleClick)
						{
							if (!this.$bREST.userType_isAdmin) { return "perms"; }
							return model.canDelete;
						},
					}),
					...B_REST_Vuetify_GenericList.RowAction.defineCommonAction_edit({}),
				},
			},
			filters: {
				pk:                        {fieldNamePath:"pk", op:B_REST_Vuetify_GenericList.FILTER_OPS.OP_EQ_IN},
				status:                    {fieldNamePath:"status", multiple:true},
				plaintiff_fk:              {fieldNamePath:"plaintiff_fk", op:B_REST_Vuetify_GenericList.FILTER_OPS.OP_EQ_IN},
				recipient_fk:              {fieldNamePath:"recipient_fk", op:B_REST_Vuetify_GenericList.FILTER_OPS.OP_EQ_IN},
				recipient_allMedalNumbers: {fieldNamePath:"recipient.calc_concatMedalNumbers_history"},
				createdDT:                 {fieldNamePath:"createdDT"},
				planned_date:              {fieldNamePath:"planned_date"},
				priority:                  {fieldNamePath:"priority", as:"radioGroup"},
				assigned_to_fk:            {fieldNamePath:"assigned_to_fk", items:"employeeList"},
				animal_specie:             {fieldNamePath:"animal.specie"},
				animal_mainBreed:          {fieldNamePath:"animal.main_breed_fk", items:"allBreedList"},
				animal_crossBreed:         {fieldNamePath:"animal.cross_breed_fk", items:"allBreedList"},
				animal_primaryHairColor:   {fieldNamePath:"animal.hair_color_primary_fk", items:"hairColorList"},
				animal_secondaryHairColor: {fieldNamePath:"animal.hair_color_secondary_fk", items:"hairColorList"},
				animal_weight:             {fieldNamePath:"animal.weight"},
				animal_sex:                {fieldNamePath:"animal.sex"},
				recipient_civicNumber:     {fieldNamePath:"recipient.civic_number"},
				recipient_street:          {fieldNamePath:"recipient.municipality_street_fk", picker:"municipalityStreetList"},
				recipient_municipality:    {fieldNamePath:"recipient.municipality_fk", items:"municipalityList"},
			},
		}),
		data() {
			return {
				jsGmap: null,
				isGmapUpdateActivated: false,   // To active/deactivate the GPS button
				routeMarkers: [],               // All the interventions positions
				employeMarkers: [],				// All the employees positions
				selfMarker: null,               //Google Marker of logged user
				directionsRenderers: [],
				googleMapsLinks: [],
			};
		},
		created()
		{
			this.$bREST.utils.console_todo([`Street filter supposed to be a picker of street per municipality, but it'll be done later in the proj`]);
		},
		async mounted() {
			await this._load_gmapJs(); //Initialise the map
			await this._routes_reload(); //Load markers and routes
			
			if (this.routeMarkers.length>0) { this.centerMapOnMarkers();         }
			else                            { this.centerMapOnCurrentLocation(); } //Or map canvas will be blank; even controls won't show up :/
			
			this.employeeMarkers_startCron();
		},
		methods: {
			// Change the order number directly in the list
			async set_planned_order(model) {
				const post_request = new this.$bREST.POST("/interventions/{intervention}/setPlannedOrder", {intervention:model.pk});
				post_request.data_set("planned_order", model.select('planned_order').val);
			
				const post_response = await this.$bREST.call(post_request);
						
				if(post_response.isSuccess)
				{
					this.$bREST.notifs_saved_success_generic();
				}
				else
				{
					this.$bREST.notifs_saved_failure_generic(); 
				}
				await this._routes_reload();
			},
			// Initialisation of the Google Map, add the control button (center all marker, center current location)
			async _load_gmapJs() {
				return new Promise(async (resolve,reject) => {
					const loader = new Loader({
						apiKey: this.$bREST.businessConfig.custom.gmaps_frontendMap_domainKey,
						version: "weekly",
					});
					try {
						await loader.load();
						this.jsGmap = new google.maps.Map(this.$refs.gMap);
						const controlDiv = document.createElement("div"); //container for the buttons
						// default button configuration
						const createButton = (text, clickHandler) => {
							const button = document.createElement("button");
							button.style.backgroundColor = "#fff";
							button.style.border = "2px solid #fff";
							button.style.borderRadius = "3px";
							button.style.boxShadow = "0 0px 3px rgba(0,0,0,.3)";
							button.style.color = "rgb(25,25,25)";
							button.style.cursor = "pointer";
							button.style.fontFamily = "Roboto,Arial,sans-serif";
							button.style.fontSize = "16px";
							button.style.lineHeight = "38px";
							button.style.margin = "10px 10px";
							button.style.padding = "0 5px";
							button.style.textAlign = "center";
							button.type = "button";
							button.textContent = text;
							button.className = "custom-control-button";
							button.addEventListener("click", clickHandler);
							return button;
						};
						// Create a button for center on markers
						const controlButtonCenterMapOnMarkers = createButton(this.t('buttonCenterMarker'), () => {
							this.centerMapOnMarkers();
						});
						// Create a button for center on current location
						const controlButtonCenterMapOnCurrentLocation = createButton(this.t('buttonCenterOnPosition'), () => {
							this.centerMapOnCurrentLocation();
						});
						// Add buttons to controlDiv
						controlDiv.appendChild(controlButtonCenterMapOnMarkers);
						controlDiv.appendChild(controlButtonCenterMapOnCurrentLocation);
						// Position of the custom control elements on the map
						this.jsGmap.controls[google.maps.ControlPosition.TOP_LEFT].push(controlDiv);
						// Use a promise to indicate that the map is loaded
						resolve();
					} catch (e) {
						 reject(e);
					}	
				});
			},
			// Delete old markers and directions, get the new markers and initiate the new directions in order
			async _routes_reload() {
				// Delete old markers
				this.routeMarkers = [];
				// Remove directions from the map
				this.directionsRenderers.forEach((directionsRenderer) => {
					directionsRenderer.setMap(null);
				});
				// Remove directions from data
				this.directionsRenderers = [];
				// Get new markers
				const post_request = new this.$bREST.POST("/interventions/getMarkers");
				const post_response = await this.$bREST.call(post_request);
				const markersResponse = post_response.data;
				
				// Get valid waypoints
				const parsedRouteMarkers = [];
				for (const loop_user of markersResponse.allUsers) {
					if (loop_user.waypoints && loop_user.waypoints.length > 0) {
						const validWaypoints = [];
						for (const loop_wp of loop_user.waypoints) {
							if (loop_wp && loop_wp.lat !== null && loop_wp.lng !== null) {
								validWaypoints.push({
									lat: loop_wp.lat,
									lng: loop_wp.lng,
									pk: loop_wp.intervention_fk,
									address : loop_wp.label,
									municipality : loop_wp.municipality
								});
							}
						}
						if (validWaypoints.length > 0) {
							parsedRouteMarkers.push({
								firstName: loop_user.firstName,
								lastName: loop_user.lastName,
								color: loop_user.color,
								waypoints: validWaypoints,
								totalKM: loop_user.totalKM
							});
						}
					}
				}
				this.routeMarkers = parsedRouteMarkers;
				
				// Show new direction
				for (const loop_markerSet of this.routeMarkers) {
					await this._routes_reload_createOneRoute(loop_markerSet.waypoints, loop_markerSet.color);
				}
				// Generate the Google Map native link for each routes
				this.generateGoogleMapsLinks();
			},
			// With markersData recreate direction between all the markers
			async _routes_reload_createOneRoute(markersData, routeColor) {
				return new Promise((resolve,reject) =>
				{
					const directionsService = new google.maps.DirectionsService();
					const directionsDisplay = new google.maps.DirectionsRenderer({
						suppressMarkers: true,
						preserveViewport: true,
						polylineOptions: {
							strokeWeight: 6,
						},
					});
					directionsDisplay.setMap(this.jsGmap);
					this.directionsRenderers.push(directionsDisplay);

					const waypoints = markersData.map((loop_markerData, loop_idx) => {
						if (loop_markerData.lat===null || loop_markerData.lng===null) { return; } //Should never happen, now that RouteParser_Intervention::getMapMarkers() skims them
						
						const loop_marker = new google.maps.Marker({
							position: { lat: loop_markerData.lat, lng: loop_markerData.lng },
							label: {
								// text: `Intervention #${loop_markerData.pk}`,
								text: `#${loop_markerData.pk}`,
								fontWeight: 'bold',
								fontSize: '16px',
							},
							icon: {
								path: google.maps.SymbolPath.CIRCLE,
								scale: 20,
								fillColor: routeColor,
								fillOpacity: 1,
								strokeColor: "black",
								strokeWeight: 2,
							},
							map: this.jsGmap,
						});
						
						// Create address popup for the marker
						const infoWindowContent = `#${loop_markerData.pk}<br/>${loop_markerData.address}<br/>${loop_markerData.municipality}<br/>Québec, Canada`;
						const infoWindow = new google.maps.InfoWindow({content:infoWindowContent});
						loop_marker.addListener('click', () => infoWindow.open(this.jsGmap,loop_marker));
						
						return {
							location: loop_marker.getPosition(),
						};
					});

					const origin = waypoints[0].location;
					const destination = waypoints[waypoints.length - 1].location;
			
					// Create a list of all points except the origin and destination
					const allWaypoints = waypoints.slice(1, waypoints.length - 1);

					// Update the request to include all points in order
					const request = {
						origin,
						destination,
						waypoints: allWaypoints.map(loop_waypoint => ({
							location: loop_waypoint.location,
							stopover: true,
						})),
						travelMode: "DRIVING",
					};
					
					// Update the road line color in polylineOptions
					directionsDisplay.setOptions({
						polylineOptions: {
							strokeWeight: 6,
							strokeColor: routeColor,
						},
					});

					try
					{
						directionsService.route(request, async(result, status) => {
							if (status === "OK")
							{
								directionsDisplay.setDirections(result);
								resolve();
							}
							else { reject(); }
						});
					}
					catch ($e)
					{
						reject();
					}
				});
			},
			// Set the position and zoom of the map to show all markers
			centerMapOnMarkers() {
				if (this.routeMarkers.length > 0) {
					const bounds = new google.maps.LatLngBounds();
					for (const loop_markerSet of this.routeMarkers) {
						for (const loop_markerData of loop_markerSet.waypoints) {
							// Vérifiez que lat et lng ne sont pas nuls
							if (loop_markerData.lat===null || loop_markerData.lng===null) { continue; }
							
							const position = new google.maps.LatLng(loop_markerData.lat, loop_markerData.lng);
							bounds.extend(position);
						}
					}
					
					this.jsGmap.fitBounds(bounds);
				}
			},
			// Get current user position and zoom on it
			centerMapOnCurrentLocation() {
				if ("geolocation" in navigator) {
					navigator.geolocation.getCurrentPosition(position => {
						const userLatLng = {
							lat: position.coords.latitude,
							lng: position.coords.longitude
						};
						this.jsGmap.setCenter(userLatLng);
						this.jsGmap.setZoom(15);
					});
				} else {
					alert(this.t('noGeolocalisation'));
				}
			},
			async gpsTracker_askPosition()
			{
				try
				{
					return await new Promise((resolve,reject) =>
					{
						navigator.geolocation.getCurrentPosition(resolve,reject);
					});
				}
				catch (e) { return null; }
			},
				
			async gpsTracker_startCron()
			{
				this.isGmapUpdateActivated = true; // Disable GPS button
				
				const position = await this.gpsTracker_askPosition();
				if (!position)
				{
					alert(this.t("gpsDisabled"));
					this.isGmapUpdateActivated = false;
					return;
				}
				
				await this._gpsTracker_startCron_update();
				// Reload every X time the position of the employees
				this.coreMixin_setInterval(() => this._gpsTracker_startCron_update(), this.$bREST.consts.GMAP_UPDATE_POSITION_DURATION);
			},
				// Get the position of the employe and save lat lng in DB
				async _gpsTracker_startCron_update()
				{
					const position = await this.gpsTracker_askPosition(); //NULL or obj
					if (!position) { return; }
					
					if (this.selfMarker) { this.selfMarker.setMap(null); }
					this.selfMarker = this._makeEmployeeMarker(position.coords.latitude, position.coords.longitude, "Moi","Moi", "white");
					
					try
					{
						const post_request = new this.$bREST.POST("/interventions/gpsTracker_updatePosition");
						post_request.data_set("latitude",  position.coords.latitude);
						post_request.data_set("longitude", position.coords.longitude);
						await this.$bREST.call(post_request);
					}
					catch (e) {}
				},
			async employeeMarkers_startCron()
			{
				await this._employeeMarkers_startCron_reLoad(); //load employees with activated GPS
				this.coreMixin_setInterval(() => this._employeeMarkers_startCron_reLoad(), this.$bREST.consts.GMAP_UPDATE_POSITION_DURATION);
			},
				// Load lat lng of the employees and show them on the map
				async _employeeMarkers_startCron_reLoad()
				{
					try
					{
						// Delete all employees actual markers
						this.employeMarkers.forEach((loop_marker) => {
							loop_marker.setMap(null);
						});
						this.employeMarkers = [];
						
						// Get all active employees position, color
						const post_request = new this.$bREST.POST("/interventions/currentGpsPosition");
						const post_response = await this.$bREST.call(post_request);
						const gpsUserList = post_response.data.gpsUserList;

						// Show the employees on the map with a marker of their color
						gpsUserList.forEach(loop_user =>
						{
							if (loop_user.calc_latitude===null || loop_user.calc_longitude===null) { return; }
							const marker = this._makeEmployeeMarker(loop_user.calc_latitude, loop_user.calc_longitude, loop_user.label,"ICI", loop_user.color);
							this.employeMarkers.push(marker);
						});
					} catch (error) { }
				},
			_makeEmployeeMarker(lat, lng, longText,shortText, fillColor)
			{
				const marker = new google.maps.Marker({
					position: {lat, lng},
					label: {
						text: shortText,
						fontWeight: 'bold',
						fontSize: '16px',
					},
					icon: {
						path: google.maps.SymbolPath.FORWARD_OPEN_ARROW,
						scale: 10,
						fillColor,
						fillOpacity: 1,
						strokeColor: "black",
						strokeWeight: 2,
					},
					map: this.jsGmap,
					animation:google.maps.Animation.BOUNCE,
				});
				
				// Create address popup for the marker
				const infoWindow = new google.maps.InfoWindow({content:longText});
				marker.addListener('click', () => infoWindow.open(this.jsGmap,marker));
				
				return marker;
			},
			generateGoogleMapsLinks() {
				this.googleMapsLinks = [];
				for (const loop_route of this.routeMarkers) {
					const wp = loop_route.waypoints;
					const origin = `${wp[0].lat},${wp[0].lng}`;                                                 // The first marker of the routes
					const destination = `${wp[wp.length - 1].lat},${wp[wp.length - 1].lng}`;                    // The last marker of the routes
					const waypoints = wp.slice(1, -1).map(markerData => `${markerData.lat},${markerData.lng}`); // All the markers between origin and destination
					
					// Calculate the total distance of the entire route by addition of the distances in the distances array
					const total_distance = Math.floor(loop_route.totalKM);
					const userName = loop_route.firstName+" "+loop_route.lastName;
					const numberOfPoints = wp.length;
					const googleMapsLink = `https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${destination}&waypoints=${waypoints.join('|')}`;
					this.googleMapsLinks.push({ link: googleMapsLink, label: `${userName} - ${numberOfPoints} points - ${total_distance} km total` });
				}
			}
		},
	};
	
</script>

<style scoped>

	.br-generic-list:deep(.intervention-priority--urgent) {
		background: red;
		color: white;
	}

</style>