<template>
	<br-generic-form-base :derived-component="_self">
		
		<template #fields>
			<v-row>
				<v-col cols="12"> <br-field-db :model="model" field="pk" disabled /> </v-col>
				
				<v-col cols="12"> <br-field-db v-if="model.isNew" :model="model" field="type" /> </v-col>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="createdDT" /> </v-col>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="created_by_fk" as="select" items="employeeList" /> </v-col>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="completion_date" /> </v-col>
				<v-col cols="12" md="6"> 
					<fk-picker-w-view-add-btns :model="model" field="animal_fk" picker="animalList" :pickerOptions="animal_pickerOptions" @picker:select="animal_onSelect" moduleName="animal" />
				</v-col>
					<!-- WARNING: Don't add fields here if they're only for ONE type of contract -->
				
				
				
				
				<v-col cols="12"><v-divider /></v-col>
				<v-col cols="12"><h2>{{ contract_name }}</h2></v-col>
				
				
				
				
				<template v-if="isAbandonment">
					<v-col cols="12" md="6"> <br-field-db :model="model" field="abandon_reason"/>  </v-col>
					<v-col cols="12" md="6"> <br-field-db :model="model" field="abandon_source" /> </v-col>
					<v-col cols="12" md="6"> <br-field-db :model="model" field="abandon_employee_fk" as="select" items="employeeList" /> </v-col>
					<v-col cols="12" md="6"> <br-field-db :model="model" field="abandon_details" as="textarea" /> </v-col>
				</template>
				
				<template v-if="isSearch">
					<v-col cols="12" md="6"> <br-field-db :model="model" field="lost_date" /> </v-col>
					<v-col cols="12" md="6"> <br-field-db :model="model" field="search_details" /> </v-col>
				</template>
				
				<template v-if="isNonCompliance">
					<v-col cols="12" md="12"> <br-field-db :model="model" field="nonCompliance_origin" /> </v-col>
					<v-col cols="12" md="6"> <br-field-db :model="model" field="nonCompliance_follow_up" /> </v-col>
					<v-col cols="12" md="6"> <br-field-db :model="model" field="nonCompliance_deadline_dt" /> </v-col>
				</template>
				
				<template v-if="isNonCompliance || isInfraction">
					<v-col cols="12" md="6"> <br-field-db :model="model" field="infraction_reason"  multiple as="autocomplete" items="interventionReasonList" /> </v-col>
					<v-col cols="12" md="6"> <br-field-db :model="model" field="infraction_details" /> </v-col>
					<v-col cols="12" md="12"> <br-field-db :model="model" field="law_regulation" /> </v-col>
				</template>
				
				<v-divider />
				<v-col cols="12" class="mb-5">
					<v-text-field disabled label="SIGNATURE" />
					<signature-w-viewer-and-btn :model="model" field-name="signature" date-field-name="signature_date" />
					<br-field-db disabled :model="model" field="signature_date" />
					<div class="font-italic text-caption">À venir après la présentation actuelle</div>
				</v-col>
			</v-row>
		</template>
		
		<template #actions>
			<v-btn v-if="invoice_has" color="primary" @click="invoice_view">{{ t("invoice.view.btn") }}</v-btn>
			<v-btn v-else             color="primary" @click="invoice_create" :disabled="!invoice_canCreate">{{ t("invoice.create.btn") }}</v-btn>
			<v-spacer />
			
			<v-btn :disabled="model.isNew || generatePDF_isSpinning" :loading="generatePDF_isSpinning" color="primary" @click="generatePDF">{{ t("generatePDF.btn") }}</v-btn>
			
			<v-spacer />
			<v-btn color="primary" @click.stop.prevent="awaitUnsavedChangesSaved" :disabled="!shouldSavingBeEnabled">{{ t_alt("save.label") }}</v-btn>
		</template>
		
	</br-generic-form-base>
</template>

<script>
	
	import { B_REST_Vuetify_GenericFormBase_createMixin } from "@/bREST/core/implementations/vue/vuetifyComponents/genericModules/form/BrGenericFormBase.vue";

	export default {
		name: "contractForm",
		components: {
			FkPickerWViewAddBtns: ()=>import("@/custom/components/FkPickerWViewAddBtns.vue"),
			SignatureWViewerAndBtn: () => import("@/custom/components/SignatureWViewerAndBtn.vue"),
		},
		mixins: B_REST_Vuetify_GenericFormBase_createMixin({
			modelName:          "Contract",
			apiBaseUrl:         "/contracts/",
			needsAccessToken:   true,
			showValidationErrs: true,
			showSkeletonLoader: true,
		 // autoUpdateInterval: 2000,
			requiredFields:     "<all>",
			todos: [
				//Arr of {isDone,isBug,text} that will appear automatically at the top of the form
			],
			async modelReady()
			{
				if (this.model.isNew)
				{
					if (!this.citizen_pk_fromPathVars) { this.$bREST.throwEx(`Expected a citizen PK`); }
					
					this.model.select("citizen_fk").val = this.citizen_pk_fromPathVars;
					
					this.modelField_type.val = this.$bREST.consts.contractTypes.ABANDONMENT;
					//NOTE: If model isn't undefined in created, it means it's existing and we're loading it and will get it afterLoad(), but we only care about new ones here so it's ok
				}
			},
			async afterLoad(response,models)                 { },
			async customValidator()                          { },
			async beforeSave(request,model)                  { },
			async afterSave(response,model,isSuccess,wasNew) { },
		}),
		data()
		{
			return {
				generatePDF_isSpinning: false,
			};
		},
		computed: {
			citizen_pk_fromPathVars() { return this.$bREST.routes_current_pathVars_parent_pkTag;                     }, //NOTE: Should always be filled. For when route is like /citizens/:citizen/contracts/:pkTag
			modelField_type()         { return this.model.select("type");                                            },
			modelField_invoice_fk()   { return this.model.select("invoice_fk");                                      },
			contract_type()           { return this.modelField_type.val;                                             },
			contract_name()           { return this.modelField_type.enum_label;                                      },
			isAdoption()              { return this.contract_type===this.$bREST.consts.contractTypes.ADOPTION;       },
			isAbandonment()           { return this.contract_type===this.$bREST.consts.contractTypes.ABANDONMENT;    },
			isReclamation()           { return this.contract_type===this.$bREST.consts.contractTypes.RECLAMATION;    },
			isSearch()                { return this.contract_type===this.$bREST.consts.contractTypes.SEARCH;         },
			isNonCompliance()         { return this.contract_type===this.$bREST.consts.contractTypes.NON_COMPLIANCE; },
			isInfraction()            { return this.contract_type===this.$bREST.consts.contractTypes.INFRACTION;     },
			invoice_has()             { return this.modelField_invoice_fk.isNotEmpty;                                },
			invoice_canCreate()       { return !this.model.isNew && !this.invoice_has;                               },
			animal_pickerOptions()
			{
				return {
					onPrompt_setFilters: ({brFieldDb_component,brFieldDb_modelField,picker,picker_component,picker_modelList,picker_modelList_searchOptions}) =>
					{
						picker_modelList_searchOptions.f_equalOrIN("owner_fk",/*throwIfExists*/false).valOrArr = this.citizen_pk_fromPathVars;
							//IMPORTANT: Won't work if the bound BrGenericList has a filter pointing on the same fieldNamePath; the picker's filter val in the UI will overwrite the above
							//Also, sometimes doesn't work on the 1st shot and we have to close the picker & reopen. Prolly a B_REST_Vuetify_PickerDef.REUSE_MODE_IF_NOT_PROMPTING bug
					},
				};
			},
		},
		methods: {
			animal_onSelect({modelField,selection})
			{
				this.$bREST.utils.console_todo([`TODO`]);
			},
			async invoice_create()
			{
				const savedSomething = await this.awaitUnsavedChangesSaved(); //Throws on err
				
				this.$bREST.routes_go_subModuleForm_new("citizen",this.citizen_pk_fromPathVars, "invoice", /*qsa*/{contract:this.model.pk});
			},
			invoice_view() { this.$bREST.routes_go_subModuleForm_pkTag("citizen",this.citizen_pk_fromPathVars, "invoice",this.modelField_invoice_fk.val); },
			async generatePDF()
			{
				this.generatePDF_isSpinning = true;
				
				const request = new this.$bREST.GET_File("contracts/{pkTag}/generatePDF", {pkTag:this.model.pk});
				this.$bREST.call_download(request).finally(() =>
				{
					this.generatePDF_isSpinning = false;
				});
			},
		},
	}
	
</script>