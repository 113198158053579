<template>
	<br-generic-list-base :derived-component="_self">
		<!--
			🚀❓
				3 ways to get col's data, where the 3rd is an ex of if we want to make it out of multiple things:
					<template #item.someFieldOrColumnName="{ rowInfo, colInfo, modelField }">
						<span>{{ modelField.val }}</span>
						<span>{{ rowInfo.model.select("someFieldName").val }}</span>
						<span>{{ rowInfo.model.select_nonEmptyValsConcatenated("first_name|last_name") }}</span>
					</template>
			🚀❓
		-->
		
		<!-- 🚀↑app>modules>x>XList.vue>template>slots↑🚀 -->
	</br-generic-list-base>
</template>

<script>
	
	import { B_REST_Vuetify_GenericList } from "@/bREST/core/implementations/vue";
	
	export default {
		name: "breedList",
		mixins: B_REST_Vuetify_GenericList.createMixin({
			modelName: "Breed",
			fromLoader: {
				apiBaseUrl: "/breeds/",
			},
			cols: {
				is_restricted: {
					fieldNamePaths: "is_restricted",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				specie: {
					fieldNamePaths: "specie",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				name: {
					fieldNamePaths: "loc.<dbOnly>",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
					toCellContent(col,model,defaultContent)
					{
						const breedLoc_currentLang = model.select("loc").subModels.find(loop_model => loop_model.select("lang").val===this.$bREST.locale_lang);
						return breedLoc_currentLang ? breedLoc_currentLang.select("name").val : null;
					},
				},
			},
			globalActions: {
				...B_REST_Vuetify_GenericList.GlobalAction.defineCommonAction_add({}),
			},
			row: {
				checkbox: {isEnabled:true},
				actions: {
					...B_REST_Vuetify_GenericList.RowAction.defineCommonAction_edit({}),
				},
			},
			filters: {
				is_restricted: {fieldNamePath:"is_restricted"},
				specie: {fieldNamePath:"specie"},
			},
		}),
	};
	
</script>