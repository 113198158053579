<template>
	<br-generic-form-base :derived-component="_self">
		
		<template #fields>
			<v-row>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="name" /> </v-col>
			</v-row>
		</template>
		
	</br-generic-form-base>
</template>

<script>
	
	import { B_REST_Vuetify_GenericFormBase_createMixin } from "@/bREST/core/implementations/vue/vuetifyComponents/genericModules/form/BrGenericFormBase.vue";
	
	
	
	export default {
		name: "municipalityStreetForm",
		mixins: B_REST_Vuetify_GenericFormBase_createMixin({
			modelName:          "MunicipalityStreet",
			apiBaseUrl:         "/municipalityStreets/",
			showValidationErrs: true,
			showSkeletonLoader: true,
		  //autoUpdateInterval: 2000,
			requiredFields:     "<all>",
			todos: [
				//Arr of {isDone,isBug,text} that will appear automatically at the top of the form
			],
			async modelReady()
			{
				if (this.model.isNew)
				{
					const municipality = this.$bREST.routes_current_pathVars_parent_pkTag; //Can yield NULL. For when route is like /municipalities/:municipality/municipalityStreets/:pkTag
					
					if (municipality) { this.model.select("municipality_fk").val=municipality; }
				}
			},
			async afterLoad(response,models)                 { },
			async customValidator()                          { },
			async beforeSave(request,model)                  { },
			async afterSave(response,model,isSuccess,wasNew) { },
		}),
		data()
		{
			return {
				
			};
		},
		methods: {
			
		},
	}
	
</script>