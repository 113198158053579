<template>
	<br-generic-form-base :derived-component="_self">
		
		<template #fields>
			<v-row>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="arrival_d" /> </v-col>
				<v-col cols="12" md="6">
					<fk-picker-w-view-add-btns :model="model" field="intervention_fk" picker="interventionList" @picker:select="intervention_onSelect" moduleName="intervention" />
				</v-col>
				<v-col cols="12" md="6">
					<fk-picker-w-view-add-btns :model="model" field="animal_fk" picker="animalList" :pickerOptions="animal_pickerOptions" @picker:select="animal_onSelect" moduleName="animal" />
				</v-col>
				<v-col cols="12" md="6">
					<fk-picker-w-view-add-btns :model="model" field="owner_fk" picker="citizenList" @picker:select="owner_onSelect" moduleName="citizen" />
				</v-col>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="room" /> </v-col>
				<v-col cols="12" md="6" v-if="!$bREST.businessConfig.custom.animalInShelter_showOrigin"> <br-field-db :model="model" field="source" as="radioGroup" /> </v-col>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="status" as="select"/> </v-col>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="quantity" /> </v-col>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="type" as="select"/> </v-col>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="description" as="textarea" /> </v-col>
			</v-row>
		</template>
		
	</br-generic-form-base>
</template>

<script>
	
	import { B_REST_Vuetify_GenericFormBase_createMixin } from "@/bREST/core/implementations/vue/vuetifyComponents/genericModules/form/BrGenericFormBase.vue";
	
	export default {
		name: "animalInShelterForm",
		components: {
			FkPickerWViewAddBtns: ()=>import("@/custom/components/FkPickerWViewAddBtns.vue"),
		},
		mixins: B_REST_Vuetify_GenericFormBase_createMixin({
			modelName:          "AnimalInShelter",
			apiBaseUrl:         "/animalsInShelter/",
			needsAccessToken:   true,
			showValidationErrs: true,
			showSkeletonLoader: true,
		 // autoUpdateInterval: 2000,
			requiredFields:     "<all>",
			todos: [
				//Arr of {isDone,isBug,text} that will appear automatically at the top of the form
			],
			async modelReady()                               { },
			async afterLoad(response,models)                 { },
			async customValidator()                          { },
			async beforeSave(request,model)                  { },
			async afterSave(response,model,isSuccess,wasNew) { },
		}),
		computed: {
			animal_pickerOptions()
			{
				return {
					onPrompt_setFilters: ({brFieldDb_component,brFieldDb_modelField,picker,picker_component,picker_modelList,picker_modelList_searchOptions}) =>
					{
						picker_modelList_searchOptions.f_equalOrIN("owner_fk",/*throwIfExists*/false).valOrArr = this.model.select("owner_fk").val;
							//IMPORTANT: Won't work if the bound BrGenericList has a filter pointing on the same fieldNamePath; the picker's filter val in the UI will overwrite the above
							//Also, sometimes doesn't work on the 1st shot and we have to close the picker & reopen. Prolly a B_REST_Vuetify_PickerDef.REUSE_MODE_IF_NOT_PROMPTING bug
					},
				};
			}
		},
		methods: {
			async intervention_onSelect({modelField,selection})
			{
				const {plaintiff_fk,plaintiff_label, recipient_fk,recipient_label, animal_fk,animal_label} = await this.$bREST.interrelatedPickers_getInfo("intervention",selection.pk);
				const plaintiff_fk_or_recipient_fk_orNULL = plaintiff_fk || recipient_fk || null; //IMPORTANT: When we figure out which one to use, CTRL+F "plaintiff_fk_or_recipient_fk_orNULL"
				this.model.select("owner_fk").val  = plaintiff_fk_or_recipient_fk_orNULL; //NOTE: Can have none yet
				this.model.select("animal_fk").val = animal_fk;                           //NOTE: Can have none yet
					//WWARNING: Sometimes, in intervention_onSelect() but not animal_onSelect(), owner_fk's toLabel() doesn't get picked, because bound BrFieldDb::final_field_val_forWatch() doesn't get called, so neither toLabelCache()...
			},
			async animal_onSelect({modelField,selection})
			{
				const {owner_fk,owner_label} = await this.$bREST.interrelatedPickers_getInfo("animal",selection.pk);
				this.model.select("owner_fk").val = owner_fk; //NOTE: Can have no owner
			},
			owner_onSelect({modelField,selection})
			{
				this.$bREST.utils.console_todo([`TODO`]);
			},
		},
	};
	
</script>