<template>
	<br-generic-form-base :derived-component="_self">
		
		<template #fields>
			<v-row>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="specie" /> </v-col>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="loc[0].name" /> </v-col>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="is_restricted" as="radioGroup" radioGroupOrientation="horizontal" /> </v-col>
			</v-row>
		</template>
		
	</br-generic-form-base>
</template>

<script>
	
	import { B_REST_Vuetify_GenericFormBase_createMixin } from "@/bREST/core/implementations/vue/vuetifyComponents/genericModules/form/BrGenericFormBase.vue";
	
	
	
	export default {
		name: "breedForm",
		mixins: B_REST_Vuetify_GenericFormBase_createMixin({
			modelName:          "Breed",
			apiBaseUrl:         "/breeds/",
			showValidationErrs: true,
			showSkeletonLoader: true,
		  //autoUpdateInterval: 2000,
			requiredFields:     "<all>",
			todos: [
				//Arr of {isDone,isBug,text} that will appear automatically at the top of the form
			],
			async modelReady()
			{
				if (this.model.isNew)
				{
					const breedLoc = this.model.select("loc").add();
					breedLoc.select("lang").val = this.$bREST.locale_lang;
				}
				
			},
			async afterLoad(response,models)                 { },
			async customValidator()                          { },
			async beforeSave(request,model)                  { },
			async afterSave(response,model,isSuccess,wasNew) { },
		}),
	}
	
</script>