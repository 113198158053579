<template>
	<v-row>
		<br-generic-list-base :derived-component="_self">
			<template #data-table-area="{modelList}">
				<v-card v-for="(loop_model,loop_idx) in modelList.models" :key="loop_idx" class="my-10" readonly>
					<v-card-actions class="pb-0">
						<v-row justify="end" no-gutters>
							<v-col cols="auto" class="ml-2"> <v-btn icon @click="on_edit_click(loop_model)"><v-icon>mdi-pencil</v-icon></v-btn> </v-col>
							<v-col cols="auto" class="ml-2"> <v-btn icon disabled color="error"><v-icon>mdi-close</v-icon></v-btn> </v-col>
						</v-row>
					</v-card-actions>
					<v-card-text class="pa-0">
						<equipment-rental-card :model="loop_model" readonly />
					</v-card-text>
				</v-card>
			</template>
		</br-generic-list-base>
	</v-row>
</template>

<script>
	
	import { B_REST_Vuetify_GenericList } from "@/bREST/core/implementations/vue";
	
	export default {
		name: "equipmentRentalList",
		components: {
			EquipmentRentalCard: ()=>import("./EquipmentRentalCard.vue"),
		},
		mixins: B_REST_Vuetify_GenericList.createMixin({
			formComponent: () => import("./EquipmentRentalForm.vue"),
			modelName: "EquipmentRental",
			fromLoader: {
				apiBaseUrl: "/equipmentRentals/",
			},
			cols: {
				equipmentNumber: {fieldNamePaths:"number",                               style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT}},
				citizenNumber:   {fieldNamePaths:"citizen.first_name|citizen.last_name", style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT}},
				notes:           {fieldNamePaths:"notes",                                style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT}},
				delivery_d:      {fieldNamePaths:"delivery_d",                           style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT}},
				plannedPickup_d: {fieldNamePaths:"plannedPickup_d",                      style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT}},
				returned_d:      {fieldNamePaths:"returned_d",                           style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT}},
			},
			globalActions: {
				...B_REST_Vuetify_GenericList.GlobalAction.defineCommonAction_add({}),
			},
			row: {
				checkbox: {isEnabled:true},
				actions: {
					...B_REST_Vuetify_GenericList.RowAction.defineCommonAction_edit({}),
				},
			},
			filters: {
				returned_d: {fieldNamePath:"returned_d", op:B_REST_Vuetify_GenericList.FILTER_OPS.OP_NULL}, //If the date is NULL, it means we have to pick it up
			},
		}),
		methods: {
			on_edit_click(model) { this.$bREST.routes_go_moduleForm_pkTag("equipmentRental", model.pk_tag); },
		},
	};
	
</script>