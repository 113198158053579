<template>
	<v-row>
		<br-generic-list-base :derived-component="_self">
			<template #item.photo="{ rowInfo, colInfo, modelField, finalContent }">
				<div v-if="finalContent" style="float:left;" ><v-img :src="finalContent" :max-width="200" /></div>
			</template>
		</br-generic-list-base>
	</v-row>
</template>

<script>
	
	import { B_REST_Vuetify_GenericList } from "@/bREST/core/implementations/vue";
	
	const SPAD = "spad";
	const P_A = "passeportanimal";
	
	
	
	export default {
		name: "animalInShelterList",
		mixins: B_REST_Vuetify_GenericList.createMixin({
			formComponent: () => import("./AnimalInShelterForm.vue"),
			modelName: "AnimalInShelter",
			fromLoader: {
				apiBaseUrl: "/animalsInShelter/",
			},
			cols: {
				//STUFF FOR BOTH SPAD & Passeport Animal
					arrival_d: {
						fieldNamePaths:"arrival_d", style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
						isVisible(col) { return this.isVisibleFor([SPAD,P_A]); },
					},
					room: {
						fieldNamePaths:"room", style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
						isVisible(col) { return this.isVisibleFor([SPAD,P_A]); },
					},
					status: {
						fieldNamePaths:"status", style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
						isVisible(col) { return this.isVisibleFor([SPAD,P_A]); },
					},
				
				//STUFF ONLY FOR SPAD
					animal_fk: {
						fieldNamePaths:"animal_fk", style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
						isVisible(col) { return this.isVisibleFor([SPAD]); },
					},
					citizenName: {
						fieldNamePaths:"owner.<toLabel>", style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
						isVisible(col) { return this.isVisibleFor([SPAD]); },
					},
				
				//STUFF ONLY FOR Passeport Animal
					medal_number: {
						fieldNamePaths:"animal.medal_number", style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
						isVisible(col) { return this.isVisibleFor([P_A]); },
					},
					name: {
						fieldNamePaths:"animal.name", style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
						isVisible(col) { return this.isVisibleFor([P_A]); },
					},
					sterilisation_date: {
						fieldNamePaths:"animal.sterilisation_date", style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
						isVisible(col) { return this.isVisibleFor([P_A]); },
					},
					photo: {
						fieldNamePaths:null, style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
						isVisible(col) { return this.isVisibleFor([P_A]); },
						toCellContent(col,model,defaultContent) { return model.select("animal.photo")?.ifSingle_apiUrl_wDomainName ?? null; },
					},
			},
			globalActions: {
				...B_REST_Vuetify_GenericList.GlobalAction.defineCommonAction_add({}),
			},
			row: {
				checkbox: {isEnabled:true},
				actions: {
					...B_REST_Vuetify_GenericList.RowAction.defineCommonAction_edit({}),
				},
			},
			filters: {
				owner_allMedalNumbers:     {fieldNamePath:"owner.calc_concatMedalNumbers_history"},
				owner_pk:                  {fieldNamePath:"owner.pk"},
				room:                      {fieldNamePath:"room"},
				status:                    {fieldNamePath:"status"},
				animal_specie:             {fieldNamePath:"animal.specie"},
				animal_mainBreed:          {fieldNamePath:"animal.main_breed_fk", items:"allBreedList"},
				animal_crossBreed:         {fieldNamePath:"animal.cross_breed_fk", items:"allBreedList"},
				animal_primaryHairColor:   {fieldNamePath:"animal.hair_color_primary_fk", items:"hairColorList"},
				animal_secondaryHairColor: {fieldNamePath:"animal.hair_color_secondary_fk", items:"hairColorList"},
				animal_weight:             {fieldNamePath:"animal.weight"},
				animal_sex:                {fieldNamePath:"animal.sex"},
				owner_civicNumber:         {fieldNamePath:"owner.civic_number"},
				owner_street:              {fieldNamePath:"owner.municipality_street_fk", picker:"municipalityStreetList"},
				owner_municipality:        {fieldNamePath:"owner.municipality_fk", items:"municipalityList"},
			},
		}),
		created()
		{
			this.$bREST.utils.console_todo([`Street filter supposed to be a picker of street per municipality, but it'll be done later in the proj`]);
		},
		methods: {
			isVisibleFor(businessTags) { return businessTags.includes(this.$bREST.businessConfig.businessTag); },
		},
	};
	
</script>
