<template>
	<br-generic-form-base :derived-component="_self">
		
		<template #fields>
			<v-row>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="name" /> </v-col>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="lawMoreStrict" as="radioGroup" radioGroupOrientation="horizontal" /> </v-col>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="renewalMD" /> </v-col>
				
				<v-col cols="12">
					<v-expansion-panels v-model="openPanels" accordion>
						<!-- pricingInfo -->
						<v-expansion-panel key="pricingInfo">
							<v-expansion-panel-header>
								{{ t("pricingInfo.title") }}
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<v-row>
									<v-col cols="12" md="4"> <v-select v-model="dogs_type" :items="types" :label="t('pricingInfo.dogs')" @change="on_change()"></v-select> </v-col>
									<template v-if="dogs_type_not_null">
										<v-col cols="12" md="4"> <v-text-field v-model="dogs_max" type="number" :label="t('pricingInfo.max')" @change="on_change()"></v-text-field> </v-col>
										<v-col cols="12" md="4"> <v-text-field v-model="dogs_prices" :label="t('pricingInfo.prices')" @change="on_change()"></v-text-field> </v-col>
									</template>
								</v-row>
								<v-row>
									<v-col cols="12" md="4"> <v-select v-model="cats_type" :items="types" :label="t('pricingInfo.cats')" @change="on_change()"></v-select> </v-col>
									<template v-if="cats_type_not_null">
										<v-col cols="12" md="4"> <v-text-field v-model="cats_max" type="number" :label="t('pricingInfo.max')" @change="on_change()"></v-text-field> </v-col>
										<v-col cols="12" md="4"> <v-text-field v-model="cats_prices" :label="t('pricingInfo.prices')" @change="on_change()"></v-text-field> </v-col>
									</template>
								</v-row>
								<v-row>
									<v-col cols="12" md="4"> <v-text-field v-model="max_total" type="number" :label="t('pricingInfo.max_total')" @change="on_change()"></v-text-field> </v-col>
								</v-row>
							</v-expansion-panel-content>
						</v-expansion-panel>
						<!-- municipalityStreets -->
						<br-generic-form-base-sub-model-list :form="self" route-names="municipality>municipalityStreet" :accordion-title="t('tabs.municipalityStreet.title')" />
					</v-expansion-panels>
				</v-col>
			</v-row>
		</template>
		
	</br-generic-form-base>
</template>

<script>
	
	import { B_REST_Vuetify_GenericFormBase_createMixin } from "@/bREST/core/implementations/vue/vuetifyComponents/genericModules/form/BrGenericFormBase.vue";
	import MyApp from "@/custom/App.js";
	
	const TYPE_NONE = MyApp.consts.municipalityPricingInfoTypes.NONE;
	
	
	
	export default {
		name: "municipalityForm",
		mixins: B_REST_Vuetify_GenericFormBase_createMixin({
			modelName:          "Municipality",
			apiBaseUrl:         "/municipalities/",
			showValidationErrs: true,
			showSkeletonLoader: true,
		  //autoUpdateInterval: 2000,
			requiredFields:     "<all>",
			todos: [
				//Arr of {isDone,isBug,text} that will appear automatically at the top of the form
			],
			async modelReady() {
				if (!this.model.isNew)
				{
					let pricingInfo = this.model.select("pricingInfo").val;
					
					this.dogs_type   = pricingInfo.dogs.type;
					this.dogs_max    = this.dogs_type_not_null ? pricingInfo.dogs.max : 0;
					this.dogs_prices = this.dogs_type_not_null ? pricingInfo.dogs.prices.join(",") : "0";
					this.cats_type   = pricingInfo.cats.type;
					this.cats_max    = this.cats_type_not_null ? pricingInfo.cats.max : 0;
					this.cats_prices = this.cats_type_not_null ? pricingInfo.cats.prices.join(",") : "0";
					this.max_total   = pricingInfo.max_total;
				}
			},
			async afterLoad(response,models)                 { },
			async customValidator()                          { },
			async beforeSave(request,model)                  { },
			async afterSave(response,model,isSuccess,wasNew) { },
		}),
		data()
		{
			return {
				openPanels: [],
				dogs_type: TYPE_NONE,
				dogs_max: 0,
				dogs_prices: "0",
				cats_type: TYPE_NONE,
				cats_max: 0,
				cats_prices: "0",
				max_total: null,
			};
		},
		methods: {
			on_change()
			{
				const newPricingInfo = {
					dogs:      {type:this.dogs_type},
					cats:      {type:this.cats_type},
					max_total: this.max_total != "" ? parseFloat(this.max_total) : null,
				};
				this._on_change_which(newPricingInfo, "dogs");
				this._on_change_which(newPricingInfo, "cats");
				
				this.model.select("pricingInfo").val = newPricingInfo;
			},
				_on_change_which(newPricingInfo, which)
				{	
					const fieldName_x_prices = `${which}_prices`;
					const fieldName_x_max    = `${which}_max`;
					
					if (this[`${which}_type_not_null`])
					{
						if (this[fieldName_x_prices]==="") { this[fieldName_x_prices]="0"; }
						this[fieldName_x_prices] = this[fieldName_x_prices].replace(/[^\d.\,]/g, '');
						
						newPricingInfo[which].max    = parseInt(this[fieldName_x_max]);
						newPricingInfo[which].prices = this[fieldName_x_prices].split(",").map(loop_price => parseFloat(loop_price));
					}
					else
					{
						this[fieldName_x_max]    = 0;
						this[fieldName_x_prices] = "0";
					}
				},
		},
		computed: {
			dogs_type_not_null() { return this.dogs_type!==TYPE_NONE; },
			cats_type_not_null() { return this.cats_type!==TYPE_NONE; },
			types()
			{
				const types = [];
				for (const loop_tag of Object.values(this.$bREST.consts.municipalityPricingInfoTypes))
				{
					types.push({value:loop_tag, text:this.t(`pricingInfo.type.${loop_tag}`)});
				}
				return types;
			}
		},
	}
	
</script>