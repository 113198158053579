<template>
	<br-generic-list-base :derived-component="_self">
		
		<!-- 🚀↑app>modules>x>XList.vue>template>slots↑🚀 -->
	</br-generic-list-base>
</template>

<script>
	
	import { B_REST_Vuetify_GenericList } from "@/bREST/core/implementations/vue";
	
	export default {
		name: "municipalityStreetList",
		mixins: B_REST_Vuetify_GenericList.createMixin({
			modelName: "MunicipalityStreet",
			fromLoader: {
				apiBaseUrl: "/municipalityStreets/",
			},
			cols: {
				name: {
					fieldNamePaths: "name",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				municipality: {
					fieldNamePaths: "municipality.<toLabel>",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
			},
			globalActions: {
				...B_REST_Vuetify_GenericList.GlobalAction.defineCommonAction_add({}),
			},
			row: {
				checkbox: {isEnabled:true},
				actions: {
					...B_REST_Vuetify_GenericList.RowAction.defineCommonAction_edit({}),
				},
			},
			filters: {
				name: {fieldNamePath:"name"},
				/*
				IMPORTANT: Can't define the following filter, or forms' x_pickerOptions()::onPrompt_setFilters() computed hooks forcing filters in pickers
					will get overwritten by the filters in the UI. Instead of HC filters this way, should change the apiBaseUrl in the list s:
						OK:  GET /someList/filteredBySomeParentLookup/{pkTag}
						Bad: GET /someList?so_filters=f~parent_fk~eq_in~<pkTag>
					Can't define:
						municipality: {fieldNamePath:"municipality_fk", items:"municipalityList"},
				*/
			},
		}),
	};
	
</script>